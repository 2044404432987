import React from "react";
import Link from "gatsby-link";
import Scaffold from "../../../components/portfolio";

import clarityarchitecture from "../../../images/clarityarchitecture3.svg";

const ClarityTech = (props) => (
  <Scaffold pathname={props.location.pathname}>
    <h2>Clarity Coach </h2>

    <h3>Technology Stack</h3>

    <p>
      Clarity Coach is written using Typescript, with select parts being written
      in Java and Go, for performance or external library requirements.
    </p>

    <p>
      The front end uses React for the view layer, makes use of GraphQL for data
      fetching (using the excellent Apollo Client library), is bundled all
      together with webpack, and tested using Mocha.
    </p>

    <p>
      The back end is made up of functions that are uploaded to AWS Lambda, and
      longer running services that are uploaded to EC2 instances. These sit
      behind an API Gateway instance, and are coordinated using Step Functions.
      This architecture makes it very easy to test functions, as it's hard to
      write functions responsible for too much, and is trivially scalable and
      cost efficient on Lambda.
    </p>

    <img src={clarityarchitecture} />

    <p>Different layers of the stack are coloured.</p>
    <ul>
      <li className="blueHighlight">
        <span style={{ background: "rgb(179, 215, 238)" }}>Static Storage</span>{" "}
        makes use of S3 for file storage and Cloudfront for file delivery.
      </li>
      <li className="greenHighlight">
        The{" "}
        <span style={{ background: "rgb(155, 213, 202)" }}>
          API and proxy layer
        </span>{" "}
        checks permissions and logs access.
      </li>
      <li className="yellowHighlight">
        The{" "}
        <span style={{ background: "rgb(254, 219, 172)" }}>compute layer</span>{" "}
        is made up of auto scaling lambda functions, as well as Step Functions
        which invoke services hosted on EC2, behind a scaling group.
      </li>
      <li className="redHighlight">
        The{" "}
        <span style={{ background: "rgb(253, 186, 177)" }}>dynamic data</span>{" "}
        storage layer contains several DynamoDB tables behind memcached
        instances acting as a write-through cache.
      </li>
      <li className="purpleHighlight">
        The{" "}
        <span style={{ background: "rgb(209, 189, 210)" }}>external API</span>{" "}
        layer is made up of external services contacted through the compute
        layers.
      </li>
    </ul>

    <Link to="/portfolio/clarity/growth/">Next</Link>
  </Scaffold>
);

export default ClarityTech;
